$margin-large: 20px;

$blue: #2e4250;
$blue-light: rgb(107, 186, 241);
$aa-blue: rgb(82, 118, 143);
$aa-blue-tr: rgba(82, 118, 143, 0.3);

:export {
  margin_large: $margin-large;
  blue: $blue;
  blue_light: $blue-light;
}

h1.title {
  margin: 20px;
  padding-top: 20px;
  text-align: center;
}

.aablue {
  background: $aa-blue !important;
  color: white !important;
}

.aabluetr {
  background: $aa-blue-tr !important;
  color: $aa-blue !important;
  box-shadow: 0 0 0 1px $aa-blue !important;
}

.aaloader {
  color: $aa-blue !important;
  height: 100px;
  width: auto !important;
}

.overflower {
  overflow: "scroll";
}

.flexed {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.centerer {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.ui.loader:after {
  border-color: $aa-blue transparent transparent !important;
  // border-color: #767676 transparent transparent;
    border-top-color: $aa-blue !important;
  //   border-right-color: transparent;
  //   border-bottom-color: transparent;
  //   border-left-color: transparent;
}

.modalrep {
  background: rgba(255, 0, 0, 0.164) !important;
}

.largesel {
  width: 250px;
}